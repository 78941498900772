import { apolloClient } from '@/main';
import { getCategories } from '@/modules/sale/services/graphql';
import { inject } from 'vue';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  GET_CATEGORIES: 'GET_CATEGORIES'
};

// Create a function to get the storage instance
const getStorage = () => {
  return inject('$storage');
};

export default {
  async [ACTIONS.GET_CATEGORIES]({ commit }) {
    const storage = getStorage();

    const user = await storage.getUser();

    commit(MUTATIONS.GET_CATEGORIES_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getCategories,
        variables: {
          active: true,
          tenantId: user?.tenant?.id
        }
      });
      commit(MUTATIONS.GET_CATEGORIES_SUCCESS, data.getCategories);
    } catch (error) {
      commit(MUTATIONS.GET_CATEGORIES_FAILURE, error);
    }
  }
};
